import { IColumnFilter, IFilter } from "@/types";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from "@/services/filters/filterClass";
import { getActiveUserSearch, getAffiliateSearch } from "@/api/user";
import { translateLang, translateRegistrationVerify } from "@/utils/translate";
import { CreatedColumns } from "@/services/columns/columns";
import { IRegistration } from "@/api/types/registrations";
import { CellsType, prepareItems, RawConfig } from "@/utils/CellsFactory";
import { MESSENGER_ICONS } from "@/mappings/messengers";
import SvgTrue from "@/assets/icons/true-icon.svg";
import SvgFalse from "@/assets/icons/false-icon.svg";
import { ITableHeader } from "@/utils";

export const useRegistrations = () => {
    const headers: ITableHeader[] = [
        { text: 'ID рег-ции', sortable: false, value: 'id', align: 'center' },
        { text: 'ФИО', sortable: false, value: 'full_name' },
        { text: 'ID пар-ра', sortable: false, value: 'affiliate_id' },
        { text: 'IP', sortable: false, value: 'user_identifier' },
        { text: 'User Agent', sortable: false, value: 'user_agent', width: '180px' },
        { text: 'Язык', sortable: false, value: 'language' },
        { text: 'Почта', sortable: false, value: 'email' },
        { text: 'Статус почты', sortable: false, value: 'email_confirmed', align: 'center' },
        { text: 'Телефон', sortable: false, value: 'phone' },
        { text: 'Статус тел-на', sortable: false, value: 'phone_confirmed', align: 'center' },
        { text: 'Мессенджер', sortable: false, value: 'messenger_account' },
        { text: 'Реферер', sortable: false, value: 'referrer_id' },
        { text: 'Ref transaction ID', sortable: false, value: 'ref_transaction_id' },
        { text: 'Ref URL', sortable: false, value: 'ref_url', width: '180px' },
        { text: 'From URL', sortable: false, value: 'from_url', width: '180px' },
        { text: 'Статус рег-ции', sortable: false, value: 'completed', align: 'center' },
        { text: 'ID мен-ра', sortable: false, value: 'manager_id' },
        { text: 'Менеджер', sortable: false, value: 'manager_full_name' },
        { text: 'Utm term', sortable: false, value: 'utm_term', width: '120px' },
        { text: 'Utm campaign', sortable: false, value: 'utm_campaign', width: '120px' },
        { text: 'Utm source', sortable: false, value: 'utm_source', width: '120px' },
        { text: 'Utm medium', sortable: false, value: 'utm_medium', width: '120px' },
        { text: 'Utm content', sortable: false, value: 'utm_content', width: '120px' },
        { text: 'Gid', sortable: false, value: 'gid', width: '120px' },
        { text: 'Aid', sortable: false, value: 'aid', width: '120px' },
        { text: 'Gclid', sortable: false, value: 'gclid', width: '120px' },
        { text: 'Placement', sortable: false, value: 'placement', width: '120px' },
        { text: 'Creative', sortable: false, value: 'creative', width: '120px' },
        { text: 'Rand', sortable: false, value: 'rand', width: '120px' },
        { text: 'Ref sub1', sortable: false, value: 'ref_sub1', width: '120px' },
        { text: 'Ref sub2', sortable: false, value: 'ref_sub2', width: '120px' },
        { text: 'Ref sub3', sortable: false, value: 'ref_sub3', width: '120px' },
        { text: 'Ref sub4', sortable: false, value: 'ref_sub4', width: '120px' },
        { text: 'Ref sub5', sortable: false, value: 'ref_sub5', width: '120px' },
        { text: 'Ref sub6', sortable: false, value: 'ref_sub6', width: '120px' },
        { text: 'Ref sub7', sortable: false, value: 'ref_sub7', width: '120px' },
        { text: 'Ref sub8', sortable: false, value: 'ref_sub8', width: '120px' },
        { text: 'Ref sub9', sortable: false, value: 'ref_sub9', width: '120px' },
        { text: 'Ref sub10', sortable: false, value: 'ref_sub10', width: '120px' },
        { text: 'Создана', sortable: false, value: 'created_at' },
        { text: 'Обновлена', sortable: false, value: 'updated_at' },
        { text: 'Завершена', sortable: false, value: 'completed_at' },
    ];

    const getMessengerIcon = (i: IRegistration) => {
        const mesType = i.messenger!;
        return MESSENGER_ICONS[mesType];
    };

    const cellsConfigs: RawConfig<IRegistration> = {
        [CellsType.Route]: {
            id: { name: 'registration', isChip: true, valueKey: 'id' },
            affiliate_id: { name: 'affiliate', isChip: true, valueKey: 'affiliate_id' },
            referrer_id: { name: 'affiliate', isChip: true, valueKey: 'referrer_id' },
        },
        [CellsType.Text]: {
            full_name: { template: (i: IRegistration) => `${i.first_name || ''} ${i.last_name || ''}` },
            manager_full_name: { template: (i: IRegistration) => `${i.manager_first_name || ''} ${i.manager_last_name || ''}` },
            user_agent: { clamp: 2, tooltipWidth: '270px', isCopyOnClick: true },
            language: { template: (i: IRegistration) => i.language?.toUpperCase() ?? '' },
            messenger_account: { icon: getMessengerIcon, isCopyOnClick: true, tooltip: 'Скопировать' },
            ref_url: { clamp: 1, tooltipWidth: '350px', isLink: true, isCopyOnClick: true },
            from_url: { clamp: 1, tooltipWidth: '350px', isLink: true, isCopyOnClick: true },
            utm_campaign: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            utm_source: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            utm_medium: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            utm_content: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            gid: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            aid: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            gclid: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            placement: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            creative: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            rand: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub1: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub2: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub3: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub4: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub5: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub6: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub7: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub8: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub9: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
            ref_sub10: { clamp: 1, tooltipWidth: '220px', isCopyOnClick: true },
        },
        [CellsType.Boolean]: {
            email_confirmed: {
                isIcon: true,
                isTooltip: true,
                trueTooltip: 'Подтверждена',
                falseTooltip: 'Не подтверждена',
            },
            phone_confirmed: {
                isIcon: true,
                isTooltip: true,
                trueTooltip: 'Подтверждён',
                falseTooltip: 'Не подтверждён',
            },
            completed: { isIcon: true },
        },
        [CellsType.Date]: {
            created_at: {},
            updated_at: {},
            completed_at: {},
        },
    };

    const getFormattedItems = (items: IRegistration[]) => {
        return prepareItems<IRegistration>(items, cellsConfigs);
    };

    return { headers, getFormattedItems };
};

export class RegistrationColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID рег-ции', value: 'id' },
                    { title: 'ФИО', value: 'full_name' },
                    { title: 'ID пар-ра', value: 'affiliate_id' },
                    { title: 'IP', value: 'user_identifier' },
                    { title: 'User Agent', value: 'user_agent' },
                    { title: 'Язык', value: 'language' },
                    { title: 'Почта', value: 'email' },
                    { title: 'Статус почты', value: 'email_confirmed' },
                    { title: 'Телефон', value: 'phone' },
                    { title: 'Статус тел-на', value: 'phone_confirmed' },
                    { title: 'Мессенджер', value: 'messenger_account' },
                    { title: 'Реферер', value: 'referrer_id' },
                    { title: 'Ref transaction ID', value: 'ref_transaction_id' },
                    { title: 'Ref URL', value: 'ref_url' },
                    { title: 'From URL', value: 'from_url' },
                    { title: 'Статус рег-ции', value: 'completed' },
                    { title: 'ID мен-ра', value: 'manager_id' },
                    { title: 'Менеджер', value: 'manager_full_name' },
                    { title: 'Создана', value: 'created_at' },
                    { title: 'Обновлена', value: 'updated_at' },
                    { title: 'Завершена', value: 'completed_at' },
                ],
            },
            {
                name: '',
                items: [
                    { title: 'Utm term', value: 'utm_term', default: false },
                    { title: 'Utm campaign', value: 'utm_campaign', default: false },
                    { title: 'Utm source', value: 'utm_source', default: false },
                    { title: 'Utm medium', value: 'utm_medium', default: false },
                    { title: 'Utm content', value: 'utm_content', default: false },
                    { title: 'Gid', value: 'gid', default: false },
                    { title: 'Aid', value: 'aid', default: false },
                    { title: 'Gclid', value: 'gclid', default: false },
                    { title: 'Placement', value: 'placement', default: false },
                    { title: 'Creative', value: 'creative', default: false },
                    { title: 'Rand', value: 'rand', default: false },
                    { title: 'Ref sub1', value: 'ref_sub1', default: false },
                    { title: 'Ref sub2', value: 'ref_sub2', default: false },
                    { title: 'Ref sub3', value: 'ref_sub3', default: false },
                    { title: 'Ref sub4', value: 'ref_sub4', default: false },
                    { title: 'Ref sub5', value: 'ref_sub5', default: false },
                    { title: 'Ref sub6', value: 'ref_sub6', default: false },
                    { title: 'Ref sub7', value: 'ref_sub7', default: false },
                    { title: 'Ref sub8', value: 'ref_sub8', default: false },
                    { title: 'Ref sub9', value: 'ref_sub9', default: false },
                    { title: 'Ref sub10', value: 'ref_sub10', default: false },
                ],
            },
        ];
    }
}


export class RegistrationsFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                col: 1,
                id: 'manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                remove: true,
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'referrer_id',
                label: 'Реферер',
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                type: 'number',
            }),
            new filterClass.Input({
                col: 1,
                id: 'phone',
                label: 'Телефон',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 1,
                id: 'email',
                label: 'Почта',
                type: 'string',
                select: '',
            }),
            new filterClass.Select({
                col: 1,
                id: 'language',
                label: 'Язык',
                type: 'string',
                items: ['RU', 'EN'],
                itemValue: 'id',
                itemText: 'name',
                clearable: true,
                remove: true,
            }),
            new filterClass.Select({
                col: 1,
                id: 'is_finished',
                label: 'Статус регистрации',
                select: '',
                items: [1, 0],
                clearable: true,
                isShowCheckbox: true,
                remove: true,
                type: 'number',
                translate: translateRegistrationVerify,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'created',
                label: 'Создана',
                pickerType: 'date',
                clearable: true,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'completed',
                label: 'Завершена',
                pickerType: 'date',
                clearable: true,
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'utm_term',
                label: 'UTM term',
                select: [],
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'utm_campaign',
                label: 'UTM campaign',
                select: [],
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'utm_source',
                label: 'UTM source',
                select: [],
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'utm_medium',
                label: 'UTM medium',
                select: [],
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'utm_content',
                label: 'UTM content',
                select: [],
            }),
            new filterClass.Input({
                col: 2,
                id: 'gid',
                label: 'Gid',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 2,
                id: 'aid',
                label: 'Aid',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 2,
                id: 'gclid',
                label: 'Gclid',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 2,
                id: 'placement',
                label: 'Placement',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 2,
                id: 'creative',
                label: 'Creative',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 2,
                id: 'rand',
                label: 'Rand',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub1',
                label: 'Ref Sub1',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub2',
                label: 'Ref Sub2',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub3',
                label: 'Ref Sub3',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub4',
                label: 'Ref Sub4',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub5',
                label: 'Ref Sub5',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub6',
                label: 'Ref Sub6',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub7',
                label: 'Ref Sub7',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub8',
                label: 'Ref Sub8',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub9',
                label: 'Ref Sub9',
                type: 'string',
                select: '',
            }),
            new filterClass.Input({
                col: 3,
                id: 'ref_sub10',
                label: 'Ref Sub10',
                type: 'string',
                select: '',
            }),
        ];
    }
}

export enum RegistrationItemTypes {
    Route = 'route',
    Text = 'text',
    Date = 'date',
}

export interface RegistrationItem {
    type: RegistrationItemTypes;
    label: string;
    valueKey: keyof IRegistration;
    valueSlot?: RegistrationItemTypes;
    route?: string;
    value?: string;
    tooltip?: string;
    icon?: SVGElement;
    beforeIcon?: SVGElement;
    isLink?: boolean;
    isClamp?: boolean;
    isCopyOnClick?: boolean;
}

type RegistrationCard = {
    title: string;
    items: RegistrationItem[];
}

interface RegistrationCards {
    [key: string]: RegistrationCard
}

export const useRegistrationSingle = () => {
    const Types = RegistrationItemTypes;

    const main: RegistrationItem[] = [
        { type: Types.Route, label: 'ID регистрации', valueKey: 'id' },
        { type: Types.Text, label: 'Почта', valueKey: 'email' },
        { type: Types.Route, label: 'ID партнёра', valueKey: 'affiliate_id' },
        { type: Types.Text, label: 'Телефон', valueKey: 'phone' },
        { type: Types.Text, label: 'ФИО', valueKey: 'full_name' },
        { type: Types.Text, label: 'Мессенджер', valueKey: 'messenger_account' },
        { type: Types.Text, label: 'Менеджер', valueKey: 'manager_full_name' },
        { type: Types.Date, label: 'Создана', valueKey: 'created_at' },
        { type: Types.Text, label: 'IP', valueKey: 'user_identifier' },
        { type: Types.Date, label: 'Обновлена', valueKey: 'updated_at' },
        { type: Types.Text, label: 'User agent', valueKey: 'user_agent' },
        { type: Types.Date, label: 'Завершена', valueKey: 'completed_at' },
        { type: Types.Text, label: 'Язык', valueKey: 'language' },
    ];

    const referrer: RegistrationItem[] = [
        { type: Types.Route, label: 'ID реферера', valueKey: 'referrer_id' },
        { type: Types.Text, label: 'Ref sub4', valueKey: 'ref_sub4' },
        { type: Types.Text, label: 'Ref transaction ID', valueKey: 'ref_transaction_id' },
        { type: Types.Text, label: 'Ref sub5', valueKey: 'ref_sub5' },
        { type: Types.Text, label: 'Ref URL', valueKey: 'ref_url' },
        { type: Types.Text, label: 'Ref sub6', valueKey: 'ref_sub6' },
        { type: Types.Text, label: 'From URL', valueKey: 'from_url' },
        { type: Types.Text, label: 'Ref sub7', valueKey: 'ref_sub7' },
        { type: Types.Text, label: 'Ref sub1', valueKey: 'ref_sub1' },
        { type: Types.Text, label: 'Ref sub8', valueKey: 'ref_sub8' },
        { type: Types.Text, label: 'Ref sub2', valueKey: 'ref_sub2' },
        { type: Types.Text, label: 'Ref sub9', valueKey: 'ref_sub9' },
        { type: Types.Text, label: 'Ref sub3', valueKey: 'ref_sub3' },
        { type: Types.Text, label: 'Ref sub10', valueKey: 'ref_sub10' },
    ];

    const utms: RegistrationItem[] = [
        { type: Types.Text, label: 'Utm term', valueKey: 'utm_term' },
        { type: Types.Text, label: 'Aid', valueKey: 'aid' },
        { type: Types.Text, label: 'Utm campaign', valueKey: 'utm_campaign' },
        { type: Types.Text, label: 'Gclid', valueKey: 'gclid' },
        { type: Types.Text, label: 'Utm source', valueKey: 'utm_source' },
        { type: Types.Text, label: 'Placement', valueKey: 'placement' },
        { type: Types.Text, label: 'Utm medium', valueKey: 'utm_medium' },
        { type: Types.Text, label: 'Creative', valueKey: 'creative' },
        { type: Types.Text, label: 'Utm content', valueKey: 'utm_content' },
        { type: Types.Text, label: 'Rand', valueKey: 'rand' },
        { type: Types.Text, label: 'Gid', valueKey: 'gid' },
    ];

    const cards: RegistrationCards = {
        main: { title: 'Основное', items: main },
        referrer: { title: 'Реферер', items: referrer },
        utms: { title: 'UTM-метки', items: utms },
    };

    const prepareFields = (registration: IRegistration) => {
        const longFields = ['full_name', 'manager_full_name', 'user_agent', 'utm_term', 'aid', 'utm_campaign', 'gclid', 'utm_source', 'placement', 'utm_medium', 'creative', 'utm_content', 'rand', 'gid', 'ref_sub4', 'ref_sub5', 'ref_url', 'ref_sub6', 'from_url', 'ref_sub7', 'ref_sub1', 'ref_sub8', 'ref_sub2', 'ref_sub9', 'ref_sub3', 'ref_sub10', 'ref_transaction_id'];
        const linkFields = ['ref_url', 'from_url'];
        const routeFields = ['referrer_id', 'affiliate_id'];
        const iconFields = ['phone', 'email'];

        for (const card of Object.values(cards)) {
            card.items.forEach(item => {
                const key = item.valueKey;
                const value = `${registration[key] || ''}`;
                item.value = value;
                item.valueSlot = item.type;

                if (key === 'id') {
                    item.route = `affiliates/registration/${value}`;
                }
                if (routeFields.includes(key)) {
                    item.route = `/affiliates/affiliate/${value}`;
                }
                if (longFields.includes(key)) {
                    item.isClamp = true;
                    item.tooltip = value;
                    item.isCopyOnClick = true;
                }
                if (linkFields.includes(key)) {
                    item.isLink = true;
                }
                if (key === 'language') {
                    item.value = translateLang(value);
                }
                if (key === 'full_name') {
                    item.value = `${registration.first_name || ''} ${registration.last_name || ''}`;
                }
                if (key === 'manager_full_name') {
                    item.value = `${registration.manager_first_name || ''} ${registration.manager_last_name || ''}`;
                }
                if (iconFields.includes(key)) {
                    const getIcon = (k: 'phone_confirmed' | 'email_confirmed') => registration[k] ? SvgTrue : SvgFalse;
                    item.icon = key === 'phone' ? getIcon('phone_confirmed') : getIcon('email_confirmed');

                    const getTooltip = (k: 'phone_confirmed' | 'email_confirmed') => {
                        const trueValue = k === 'phone_confirmed' ? 'Подтверждён' : 'Подтверждена';
                        const falseValue = k === 'phone_confirmed' ? 'Не подтверждён' : 'Не подтверждена';
                        return registration[k] ? trueValue : falseValue;
                    };
                    item.tooltip = key === 'phone' ? getTooltip('phone_confirmed') : getTooltip('email_confirmed');
                    item.isCopyOnClick = true;
                }
                if (key === 'messenger_account') {
                    item.beforeIcon = MESSENGER_ICONS[registration.messenger!];
                }
            });
        }
    };

    const surveyItems: RegistrationItem[] = [];
    const fillSurveys = (surveys: {answer: string, question: string}[]) => {
        surveys.forEach(i => {
            const { answer, question } = i;
            const item = { type: Types.Text, label: question, valueKey: 'survey', value: answer } as RegistrationItem;
            surveyItems.push(item);
        });
    };

    const getCards = (registration: IRegistration) => {
        prepareFields(registration);

        const survey = registration.survey;
        if (survey && survey.length > 0) {
            fillSurveys(survey);
            cards.survey = { title: 'Опрос', items: surveyItems };
        }
        return cards;
    };

    return { getCards };
};
